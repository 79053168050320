import Layout from "components/layout";
import Input from "components/form/input";
import Submit from "components/submit";
import FacebookLoginButton from "components/facebook_login_button";

import * as sessionsApi from "api/sessions";
import { persistAuth } from "utils/auth";
import { identify } from "utils/tracker";

import { useState } from "react";
import { useForm } from "react-hook-form";
import { useRouter } from "next/router";
import { useUser } from "hooks/user";
import { useNotifications } from "hooks/notifications";
import { useQueryClient } from "react-query";

export default function Login() {
  const { register, handleSubmit, formState } = useForm();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const { user, setUser } = useUser("/plano/tarefas", true);
  const { setNotification } = useNotifications();

  const queryClient = useQueryClient();

  const inputOptions = {
    register,
    formState,
    options: { disabled: loading }
  };

  if (user) return <></>;

  function onSubmit(attributes) {
    setLoading(true);
    sessionsApi
      .create(attributes)
      .then((response) => {
        const { token: authToken, user } = response.body.data;
        localStorage.clear();
        setUser(user);
        identify(user.id);
        persistAuth(authToken, attributes).then(() => {
          setNotification({
            title: `Oi ${user.name.split(" ")[0]}!`,
            text: "Que bom que você voltou :)",
            type: "success"
          });
          queryClient.invalidateQueries("subscription");
          router.push("/plano/tarefas");
        });
      })
      .catch((error) => {
        if (error.statusCode !== 422) throw error;

        setError("E-mail ou senha incorretos :/");
        setLoading(false);
      });
  }

  function handleFacebookLogin({ user, token, attributes }) {
    persistAuth(token, attributes).then(() => {
      setUser(user);
      identify(user.id);
      queryClient.invalidateQueries("subscription");
      router.push("/plano/tarefas");
    });
  }

  return (
    <Layout
      title="Login"
      description="Faça login com o seu usuário cadastrado para acessar os simulados realizados e visualizar seus gráficos de performance!"
      canonical="/login"
    >
      <main>
        <div className="mx-auto md:max-w-lg">
          <div className="md:mt-8 md:px-10 md:py-8 md:border md:border-gray-100 md:rounded-lg md:shadow-md">
            <h1 className="hidden mb-8 text-center md:block">Login</h1>
            {error && (
              <div className="bg mb-8 px-4 py-2 text-error-dark border-2 border-error rounded-md">
                {error}
              </div>
            )}

            <FacebookLoginButton
              text="Entrar com Facebook"
              className="w-full"
              onSuccess={handleFacebookLogin}
            />

            <div className="divider relative">
              <span className="px-2 uppercase">ou</span>
              <div className="h-[1px] absolute top-1/2 w-4/5 w-full bg-gray-400"></div>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Input
                name="email"
                label="E-mail"
                type="email"
                validations={{ required: true }}
                {...inputOptions}
              />

              <Input
                name="password"
                type="password"
                label="Senha"
                validations={{ required: true }}
                {...inputOptions}
              />

              <Submit loading={loading} className="btn btn-primary mt-2 w-full">
                Entrar
              </Submit>
            </form>
          </div>
        </div>
      </main>
    </Layout>
  );
}
